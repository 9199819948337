import axios from "axios";

//  DEVELOPMENT URL
// const BASE_URL = 'https://xpanse.dev.bexcart.com/';
// const BASE_URL = 'http://localhost:2023/';
const UAT_BASE_URL = 'https://www.plutuscloudserviceuat.in:8201/API/CloudBasedIntegration/V1/'

// PRODUCTION URL
const BASE_URL = 'https://api.xpansecafe.com/'
// 
export default axios.create({
    baseURL: BASE_URL,
    uatBaseURL: UAT_BASE_URL
})